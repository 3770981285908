import {
    AmbientLight,
    Clock,
    PerspectiveCamera,
    PointLight,
    Scene,
    WebGLRenderer,
    PCFSoftShadowMap,
    Color,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { INPUT_DIMENSION } from "../camera/config";
import {
    CAM_ASPECT_RATIO,
    CAM_FIELD_OF_VIEW,
    CAM_PLANE_FAR,
    CAM_PLANE_NEAR,
    LIGHT_COLOUR,
    LIGHT_INTENSITY,
    SHOULD_PRESERVE_DRAWING_BUFFER,
    CAM_POS_X,
    CAM_POS_Y,
    CAM_POS_Z,
    CONTROLS_TARGET_X,
    CONTROLS_TARGET_Y,
    CONTROLS_TARGET_Z,
    LIGHT_POS_X,
    LIGHT_POS_Y,
    LIGHT_POS_Z,
    BG_COLOUR,
} from "../mannequin/environment/config";

const mainModelRenderer = new WebGLRenderer({
    alpha: false,
    preserveDrawingBuffer: SHOULD_PRESERVE_DRAWING_BUFFER,
});

const orbitCamera = new PerspectiveCamera(
    CAM_FIELD_OF_VIEW,
    CAM_ASPECT_RATIO,
    CAM_PLANE_NEAR,
    CAM_PLANE_FAR
);

const orbitControls = new OrbitControls(
    orbitCamera,
    mainModelRenderer.domElement
);
const mainModelScene = new Scene();

const light = new PointLight(LIGHT_COLOUR, LIGHT_INTENSITY);
const bounceLight = new AmbientLight(LIGHT_COLOUR, LIGHT_INTENSITY);

const clock = new Clock();
const bgColor = new Color(0xffffff);

const initMainModelEnvironment = () => {
    mainModelRenderer.autoClear = true;
    mainModelRenderer.setSize(INPUT_DIMENSION, INPUT_DIMENSION);
    mainModelRenderer.shadowMap.type = PCFSoftShadowMap;
    mainModelRenderer.setPixelRatio(window.devicePixelRatio);

    orbitCamera.position.set(CAM_POS_X, CAM_POS_Y, CAM_POS_Z);

    // orbitControls.screenSpacePanning = CONTROLS_ALLOW_PAN_SCREEN;
    orbitControls.target.set(
        CONTROLS_TARGET_X,
        CONTROLS_TARGET_Y,
        CONTROLS_TARGET_Z
    );
    // orbitControls.enablePan = false;
    // orbitControls.enableZoom = false;
    // orbitControls.enableRotate = false;
    // orbitControls.enableDamping = false;
    orbitControls.update();

    mainModelScene.background = new Color(BG_COLOUR);

    light.position.set(LIGHT_POS_X, LIGHT_POS_Y, LIGHT_POS_Z);

    mainModelScene.add(light);
    mainModelScene.add(bounceLight);

    mainModelScene.background = bgColor;
};

export {
    mainModelRenderer,
    orbitCamera,
    clock,
    mainModelScene,
    initMainModelEnvironment,
};
