import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import { AppContext } from "../../reducer";
import { RESET } from "../../reducer/actions";
import { AppContextType } from "../../reducer/types";
import { NeutralBtn } from "../styled/buttons";
import {
    ButtonBarWrapper,
    HorizontalFlexWrapper,
    VerticalFlexWrapper,
} from "../styled/wrappers";
import {
    FlippedImagePreview,
    ImagePreview,
    Output2dImagePreviewWrapper as ImagePreviewWrapper,
} from "./styled";
import {
    AXIOS_HEADERS,
    PROGRESS_API_URL,
    URL_ENDPOINT_ID_MAP,
} from "./constants";
import {
    getBlobFromDataUrl,
    getDataUrlFromBlob,
} from "../../services/Image/blob";
import { LoadingIndicator } from "../styled/icons";

const Result: React.FC = () => {
    const { state, dispatch } = useContext(AppContext) as AppContextType;
    const { cameraScreenshot, main3dFrame, captured3dFrame, ocId, apiUrl } =
        state;

    const [output2dImageBase64, setOutput2dImageBase64] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [progressRetrievalIntervalId, setProgressRetrievalIntervalId] =
        useState(0);

    const [progressPercentage, setProgressPercentage] = useState(0);
    const [etaRelative, setEtaRelative] = useState(Infinity);
    const [currentImageBase64, setCurrentImageBase64] = useState("");

    const getProgress = () => {
        const endpointId = URL_ENDPOINT_ID_MAP[apiUrl];
        axios
            .get(PROGRESS_API_URL, { params: { endpoint_id: endpointId } })
            .then((res) => {
                setProgressPercentage(Number(res.data.progress.toFixed(2)));
                setEtaRelative(Number(res.data.eta_relative.toFixed(2)));

                if (res.data.current_image) {
                    setCurrentImageBase64(res.data.current_image);
                }
            });
    };

    const retrieveOutput2dImage = () => {
        setIsLoading(true);
        setIsError(false);
        const blobData = getBlobFromDataUrl(main3dFrame);
        const formData = new FormData();

        formData.append("file", blobData);
        console.log("apiUrl: ", apiUrl);
        axios
            .post(apiUrl, formData, {
                ...AXIOS_HEADERS,
                headers: { "oc-id": ocId },
            })
            .then((res) => {
                getDataUrlFromBlob(res.data, (dataUrl: string) => {
                    setOutput2dImageBase64(dataUrl);
                    setIsLoading(false);
                    clearInterval(progressRetrievalIntervalId);
                });
            })
            .catch(() => {
                setIsError(true);
                setIsLoading(false);
                clearInterval(progressRetrievalIntervalId);
            });
    };

    const resetCaptured3dImage = () => {
        setIsLoading(false);
        clearInterval(progressRetrievalIntervalId);
        dispatch({
            type: RESET,
            payload: undefined,
        });
    };

    useEffect(() => {
        retrieveOutput2dImage();
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const progressRetrievalIntervalId = window.setInterval(() => {
            getProgress();
        }, 1000);
        setProgressRetrievalIntervalId(progressRetrievalIntervalId);
    }, []);

    return (
        <VerticalFlexWrapper>
            <HorizontalFlexWrapper>
                {cameraScreenshot && (
                    <ImagePreviewWrapper>
                        <FlippedImagePreview
                            src={cameraScreenshot}
                            alt="Guide Canvas Frame"
                        />
                    </ImagePreviewWrapper>
                )}
                <ImagePreviewWrapper>
                    <ImagePreview
                        src={captured3dFrame}
                        alt="Captured 3D Frame"
                    />
                </ImagePreviewWrapper>
                <ImagePreviewWrapper>
                    {!isLoading && !isError && (
                        <ImagePreview
                            src={output2dImageBase64}
                            alt="Output 2D Image"
                        />
                    )}
                    {isLoading && currentImageBase64 && (
                        <ImagePreview
                            src={currentImageBase64}
                            alt="Image Progress Preview"
                        />
                    )}
                </ImagePreviewWrapper>
            </HorizontalFlexWrapper>

            <ButtonBarWrapper>
                {!isLoading && (
                    <NeutralBtn onClick={resetCaptured3dImage}>
                        Reset
                    </NeutralBtn>
                )}
                {isLoading && (
                    <VerticalFlexWrapper>
                        <LoadingIndicator>
                            Processing {progressPercentage}%
                        </LoadingIndicator>
                        <LoadingIndicator>ETA: {etaRelative}s</LoadingIndicator>
                    </VerticalFlexWrapper>
                )}
            </ButtonBarWrapper>
        </VerticalFlexWrapper>
    );
};

export default Result;
