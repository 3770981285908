import { Vector } from "kalidokit";
import { clamp } from "kalidokit/dist/utils/helpers";
import { Vector3, Euler, Quaternion } from "three";
import { VRMSchema } from "@pixiv/three-vrm";
import { Face } from "kalidokit";

import {
    DEFAULT_POSITION_COORDINATES,
    DEFAULT_ROTATION_COORDINATES,
    DEFAULT_LERP_AMOUNT,
    DEFAULT_DAMPENER,
} from "./config";
import { oldLookTargetRef } from "./instances";

const rigPosition = (
    name,
    position = DEFAULT_POSITION_COORDINATES,
    dampener = DEFAULT_DAMPENER,
    lerpAmount = DEFAULT_LERP_AMOUNT,
    vrmInstance
) => {
    if (!vrmInstance) {
        return;
    }
    const Part = vrmInstance.humanoid.getBoneNode(
        VRMSchema.HumanoidBoneName[name]
    );
    if (!Part) {
        return;
    }
    let vector = new Vector3(
        position.x * dampener,
        position.y * dampener,
        position.z * dampener
    );
    Part.position.lerp(vector, lerpAmount); // interpolate
};

// Animate Rotation Helper function
const rigRotation = (
    name,
    rotation = DEFAULT_ROTATION_COORDINATES,
    dampener = DEFAULT_DAMPENER,
    lerpAmount = DEFAULT_LERP_AMOUNT,
    vrmInstance
) => {
    if (!vrmInstance) {
        return;
    }
    const Part = vrmInstance.humanoid.getBoneNode(
        VRMSchema.HumanoidBoneName[name]
    );
    if (!Part) {
        return;
    }

    let euler = new Euler(
        rotation.x * dampener,
        rotation.y * dampener,
        rotation.z * dampener
    );
    let quaternion = new Quaternion().setFromEuler(euler);
    Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};

const rigFace = (riggedFace, vrmInstance) => {
    if (!vrmInstance) {
        return;
    }
    rigRotation("Neck", riggedFace.head, 0.7);

    // Blendshapes and Preset Name Schema
    const Blendshape = vrmInstance.blendShapeProxy;
    const PresetName = VRMSchema.BlendShapePresetName;

    // Simple example without winking. Interpolate based on old blendshape, then stabilize blink with `Kalidokit` helper function.
    // for VRM, 1 is closed, 0 is open.
    riggedFace.eye.l = Vector.lerp(
        clamp(1 - riggedFace.eye.l, 0, 1),
        Blendshape.getValue(PresetName.Blink),
        0.5
    );

    riggedFace.eye.r = Vector.lerp(
        clamp(1 - riggedFace.eye.r, 0, 1),
        Blendshape.getValue(PresetName.Blink),
        0.5
    );

    riggedFace.eye = Face.stabilizeBlink(riggedFace.eye, riggedFace.head.y);
    Blendshape.setValue(PresetName.Blink, riggedFace.eye.l);

    // Interpolate and set mouth blendshapes
    Blendshape.setValue(
        PresetName.I,
        Vector.lerp(
            riggedFace.mouth.shape.I,
            Blendshape.getValue(PresetName.I),
            0.5
        )
    );
    Blendshape.setValue(
        PresetName.A,
        Vector.lerp(
            riggedFace.mouth.shape.A,
            Blendshape.getValue(PresetName.A),
            0.5
        )
    );
    Blendshape.setValue(
        PresetName.E,
        Vector.lerp(
            riggedFace.mouth.shape.E,
            Blendshape.getValue(PresetName.E),
            0.5
        )
    );
    Blendshape.setValue(
        PresetName.O,
        Vector.lerp(
            riggedFace.mouth.shape.O,
            Blendshape.getValue(PresetName.O),
            0.5
        )
    );
    Blendshape.setValue(
        PresetName.U,
        Vector.lerp(
            riggedFace.mouth.shape.U,
            Blendshape.getValue(PresetName.U),
            0.5
        )
    );

    //PUPILS
    //interpolate pupil and keep a copy of the value
    let lookTarget = new Euler(
        Vector.lerp(oldLookTargetRef.x, riggedFace.pupil.y, 0.4),
        Vector.lerp(oldLookTargetRef.y, riggedFace.pupil.x, 0.4),
        0,
        "XYZ"
    );
    oldLookTargetRef.copy(lookTarget);
    vrmInstance.lookAt.applyer.lookAt(lookTarget);
};

export { rigPosition, rigRotation, rigFace };
