import { initCamera } from "./camera";
import { initEnvironment } from "./mannequin/environment";
import {
    initMannequinModelLoader,
    initHolistic,
    initAnimation,
} from "./mannequin";
import { initMainModelLoader, initMainModelAnimation } from "./mainModel";
import { initMainModelEnvironment } from "./mainModel/environment";

let isMocapInitialised = false;
const setIsMocapInitialised = (isInitialised: boolean) => {
    isMocapInitialised = isInitialised;
};

const initOnce = () => {
    initEnvironment();
    initMainModelEnvironment();

    initMannequinModelLoader();
    initMainModelLoader();

    initHolistic();
};
initOnce();

const initMocap = (
    videoElement: HTMLVideoElement,
    guideCanvasElement: HTMLCanvasElement
) => {
    initAnimation(videoElement, guideCanvasElement);
    initMainModelAnimation();
    initCamera(videoElement);
    setIsMocapInitialised(true);
};

export { initMocap, isMocapInitialised, setIsMocapInitialised };
