const themeColor = "#9799d3";
const themeColorLight = "#a6aaea";
const themeColorHover = "#7d7faf";
const themeColorDisabled = "#6a6a6a";

const textColor = "#ffffff";
const textColorHint = "#aaaaaa";

const subThemeColor = "#d39799";
const subThemeColorLight = "#edadb1";
const subThemeColorHover = "#c48f92";
const subThemeColorDisabled = "#aaaaaa";

const neutralColor = "#dddddd";
const neutralColorHover = "#aaaaaa";
const neutralTextColor = "#000000";

const negativeColor = "#ea0f15";
const negativeColorHover = "#c41f1f";

export {
    themeColor,
    themeColorLight,
    themeColorHover,
    themeColorDisabled,
    subThemeColor,
    subThemeColorLight,
    subThemeColorHover,
    subThemeColorDisabled,
    textColor,
    textColorHint,
    neutralColor,
    neutralColorHover,
    negativeColor,
    negativeColorHover,
    neutralTextColor,
};
