import { AxiosRequestHeaders } from "axios";

const LUNA_ENDPOINT_ID = "eoV1sahs";
const KATYA_ENDPOINT_ID = "ohsh4Coh";
const JAIME_ENDPOINT_ID = "Jie7reip";
const OC_ENDPOINT_ID = KATYA_ENDPOINT_ID;

const LUNA_API_URL = "api/luna";
const JAIME_API_URL = "api/jaime";
const KATYA_API_URL = "api/katya";
const OC_API_URL = "api/oc_female";

const URL_ENDPOINT_ID_MAP: { [key: string]: string } = {
    [LUNA_API_URL]: LUNA_ENDPOINT_ID,
    [KATYA_API_URL]: KATYA_ENDPOINT_ID,
    [JAIME_API_URL]: JAIME_ENDPOINT_ID,
    [OC_API_URL]: OC_ENDPOINT_ID,
};

const PROGRESS_API_URL = "api/gofusion_progress";
const HTTP_METHOD = "POST";
const AXIOS_HEADERS: AxiosRequestHeaders = {
    "Content-Type": "application/json",
    responseType: "blob",
};

export {
    LUNA_API_URL,
    JAIME_API_URL,
    KATYA_API_URL,
    OC_API_URL,
    URL_ENDPOINT_ID_MAP,
    PROGRESS_API_URL,
    HTTP_METHOD,
    AXIOS_HEADERS,
};
