const DEFAULT_POSITION_COORDINATES = { x: 0, y: 0, z: 0 };
const DEFAULT_ROTATION_COORDINATES = { x: 0, y: 0, z: 0 };
const DEFAULT_LERP_AMOUNT = 0.3;
const DEFAULT_DAMPENER = 1;

export {
    DEFAULT_POSITION_COORDINATES,
    DEFAULT_ROTATION_COORDINATES,
    DEFAULT_LERP_AMOUNT,
    DEFAULT_DAMPENER,
};
