const SET_CAMERA_SCREENSHOT_FRAME = "SET_CAMERA_SCREENSHOT_FRAME";
const SET_MAIN_3D_FRAME = "SET_MAIN_3D_FRAME";
const SET_CAPTURED_3D_FRAME = "SET_CAPTURED_3D_FRAME";
const SET_OC_ID = "SET_OC_ID";
const SET_API_URL = "SET_API_URL";
const RESET = "RESET";

export {
    SET_CAPTURED_3D_FRAME,
    SET_MAIN_3D_FRAME,
    SET_CAMERA_SCREENSHOT_FRAME,
    SET_OC_ID,
    SET_API_URL,
    RESET,
};
