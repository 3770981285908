import {
    AmbientLight,
    Clock,
    PerspectiveCamera,
    PointLight,
    Scene,
    WebGLRenderer,
    PCFSoftShadowMap,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { INPUT_DIMENSION } from "../../camera/config";
import {
    CAM_ASPECT_RATIO,
    CAM_FIELD_OF_VIEW,
    CAM_PLANE_FAR,
    CAM_PLANE_NEAR,
    LIGHT_COLOUR,
    LIGHT_INTENSITY_MANNEQUIN,
    SHOULD_PRESERVE_DRAWING_BUFFER,
    SHOULD_RENDER_ALPHA,
    CAM_POS_X,
    CAM_POS_Y_MANNEQUIN,
    CAM_POS_Z_MANNEQUIN,
    CONTROLS_TARGET_X,
    CONTROLS_TARGET_Y_MANNEQUIN,
    CONTROLS_TARGET_Z,
    CONTROLS_ALLOW_PAN_SCREEN,
    LIGHT_POS_X,
    LIGHT_POS_Y,
    LIGHT_POS_Z,
} from "./config";

const mannequinRenderer = new WebGLRenderer({
    alpha: SHOULD_RENDER_ALPHA,
    preserveDrawingBuffer: SHOULD_PRESERVE_DRAWING_BUFFER,
});

const orbitCamera = new PerspectiveCamera(
    CAM_FIELD_OF_VIEW,
    CAM_ASPECT_RATIO,
    CAM_PLANE_NEAR,
    CAM_PLANE_FAR
);

const orbitControls = new OrbitControls(
    orbitCamera,
    mannequinRenderer.domElement
);
const mannequinScene = new Scene();

const light = new PointLight(LIGHT_COLOUR, LIGHT_INTENSITY_MANNEQUIN);
const bounceLight = new AmbientLight(LIGHT_COLOUR, LIGHT_INTENSITY_MANNEQUIN);

const clock = new Clock();

const initEnvironment = () => {
    mannequinRenderer.autoClear = true;
    mannequinRenderer.setSize(INPUT_DIMENSION, INPUT_DIMENSION);
    mannequinRenderer.shadowMap.type = PCFSoftShadowMap;
    mannequinRenderer.setPixelRatio(window.devicePixelRatio);

    orbitCamera.position.set(CAM_POS_X, CAM_POS_Y_MANNEQUIN, CAM_POS_Z_MANNEQUIN);

    orbitControls.screenSpacePanning = CONTROLS_ALLOW_PAN_SCREEN;
    orbitControls.target.set(
        CONTROLS_TARGET_X,
        CONTROLS_TARGET_Y_MANNEQUIN,
        CONTROLS_TARGET_Z
    );
    orbitControls.enablePan = false;
    orbitControls.enableZoom = false;
    orbitControls.enableRotate = false;
    orbitControls.enableDamping = false;
    orbitControls.update();

    // scene.background = new Color(BG_COLOUR);

    light.position.set(LIGHT_POS_X, LIGHT_POS_Y, LIGHT_POS_Z);

    mannequinScene.add(light);
    mannequinScene.add(bounceLight);
};

export {
    mannequinRenderer,
    orbitCamera,
    clock,
    mannequinScene,
    initEnvironment,
};
