import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { VRM, VRMUtils } from "@pixiv/three-vrm";

import { mainModelScene } from "./environment";
import { animate } from "./callbacks";
import { LUNA_VRM_URL } from "../mannequin/constants";

const mainModelLoader = new GLTFLoader();
let mainVrmInstance = null;
let modelLoadProgress = 0;

const initMainModelAnimation = () => {
    animate();
};

const initMainModelLoader = () => {
    mainModelLoader.crossOrigin = "anonymous";
    mainModelLoader.load(
        LUNA_VRM_URL,

        (gltf) => {
            VRMUtils.removeUnnecessaryJoints(gltf.scene);

            VRM.from(gltf).then((vrm) => {
                mainModelScene.add(vrm.scene);
                mainVrmInstance = vrm;
                mainVrmInstance.scene.rotation.y = Math.PI; // Rotate model 180deg to face camera
            });
        },

        (progress) => {
            modelLoadProgress = 100.0 * (progress.loaded / progress.total);
            // console.log("Loading main model...", modelLoadProgress, "%");
        },

        (error) => console.error(error)
    );
};

export {
    initMainModelAnimation,
    initMainModelLoader,
    mainModelLoader,
    mainVrmInstance,
    modelLoadProgress,
};
