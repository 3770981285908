import {
    BASE64_PREFIX,
    DATA_URI_DELIMITER,
    MIME_TYPE_DELIMITER,
    MIME_TYPE_PAUSE,
} from "./constants";

const getBlobFromDataUrl = (dataURI: string) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf(BASE64_PREFIX) >= 0)
        byteString = atob(dataURI.split(DATA_URI_DELIMITER)[1]);
    else byteString = unescape(dataURI.split(DATA_URI_DELIMITER)[1]);

    // separate out the mime component
    const mimeString = dataURI
        .split(DATA_URI_DELIMITER)[0]
        .split(MIME_TYPE_DELIMITER)[1]
        .split(MIME_TYPE_PAUSE)[0];

    // write the bytes of the string to a typed array
    const intArr = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        intArr[i] = byteString.charCodeAt(i);
    }

    return new Blob([intArr], { type: mimeString });
};

const getDataUrlFromBlob = (blob: Blob, callback: any) => {
    const fileReader: FileReader = new FileReader();

    fileReader.onload = () => {
        callback(fileReader.result);
    };
    fileReader.readAsDataURL(blob);
};

export { getBlobFromDataUrl, getDataUrlFromBlob };
