const ASHTRA_VRM_URL =
    "https://cdn.glitch.com/29e07830-2317-4b15-a044-135e73c7f840%2FAshtra.vrm?v=1630342336981";
const SEGMENTED_VRM_URL =
    "https://555comickaia.blob.core.windows.net/gogogo-kaia-research-container/VRM/3d_segmented.vrm";
const LUNA_VRM_URL =
    "https://555comickaia.blob.core.windows.net/gogogo-kaia-research-container/VRM/luna_20230627.vrm";

const MEDIAPIPE_BIN_CDN_URL =
    "https://cdn.jsdelivr.net/npm/@mediapipe/holistic@0.5.1635989137";
const LOCAL_BIN_URL = "holistic";

const CANVAS_ELEMENT_CONTEXT_ID = "2d";

const URL_SEPARATOR = "/";

export {
    ASHTRA_VRM_URL,
    SEGMENTED_VRM_URL,
    LUNA_VRM_URL,
    MEDIAPIPE_BIN_CDN_URL,
    LOCAL_BIN_URL,
    CANVAS_ELEMENT_CONTEXT_ID,
    URL_SEPARATOR,
};
