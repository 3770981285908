const BASE64_PREFIX = "base64";
const DATA_URI_DELIMITER = ",";

const MIME_TYPE_PNG = "image/png";

const MIME_TYPE_DELIMITER = ":";
const MIME_TYPE_PAUSE = ";";

export {
    BASE64_PREFIX,
    DATA_URI_DELIMITER,
    MIME_TYPE_PNG,
    MIME_TYPE_DELIMITER,
    MIME_TYPE_PAUSE,
};
