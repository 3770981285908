import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing:border-box;
        font-family: 'Noto Sans JP', sans-serif;
        font-family: 'Titillium Web', sans-serif;

        text-rendering: optimizeLegibility;
    }

    #root{
        margin:0 auto;
        background: #e4e8ea;
        overflow: auto;
        height: 100vh;
        width: 100vw;
    }
 `;
