import React, { ChangeEvent, useContext, useRef, useState } from "react";
import { AppContext } from "../../reducer";
import {
    SET_API_URL,
    SET_CAPTURED_3D_FRAME,
    SET_MAIN_3D_FRAME,
    SET_OC_ID,
} from "../../reducer/actions";
import { AppContextType } from "../../reducer/types";
import {
    OC_ID_MAP,
    OC_SAMPLE_INPUT_MAP,
    OC_SAMPLE_OUTPUT_FULL_MAP,
} from "../MainCamera/MotionCaptureFeed/constants";
import { FileInput } from "../MainCamera/MotionCaptureFeed/styled";
import Result from "../Result";
import { OC_API_URL } from "../Result/constants";
import { ImagePreview } from "../Result/styled";
import { PositiveBtn } from "../styled/buttons";
import { MaterialIcon } from "../styled/icons";
import {
    ContentWrapper,
    VerticalFlexWrapper,
    HorizontalFlexWrapper,
} from "../styled/wrappers";
import {
    HiddenUploadBtn,
    ImagePreviewWrapper,
    PlaceholderImagePreview,
} from "./styled";
import Guide from "../Guide";

const UploadRender: React.FC = () => {
    const { state, dispatch } = useContext(AppContext) as AppContextType;
    const { ocId } = state;
    const uploadTempImageInputElement =
        useRef() as React.MutableRefObject<HTMLInputElement>;
    const { captured3dFrame } = state;
    const [tempImageDataUrl, setTempImageDataUrl] = useState("");

    const handleUploadTempImageFileBtnClick = () => {
        uploadTempImageInputElement.current?.click();
    };

    const submitTempImageFile = () => {
        dispatch({
            type: SET_API_URL,
            payload: {
                apiUrl: OC_API_URL,
            },
        });

        dispatch({
            type: SET_MAIN_3D_FRAME,
            payload: {
                main3dFrame: tempImageDataUrl,
            },
        });

        dispatch({
            type: SET_CAPTURED_3D_FRAME,
            payload: {
                captured3dFrame: tempImageDataUrl,
            },
        });
    };

    const onTempImageFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const origFile = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(origFile);
            reader.onload = () => {
                const imageDataUrl = reader.result as string;

                setTempImageDataUrl(imageDataUrl);
            };
        }
    };

    const onOcIdChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const ocId = e.target.value;
        dispatch({
            type: SET_OC_ID,
            payload: {
                ocId,
            },
        });
    };

    const isTempImageDataUrlPopulated =
        tempImageDataUrl != null &&
        tempImageDataUrl != undefined &&
        tempImageDataUrl !== "";

    const mainContent = (
        <VerticalFlexWrapper>
            <ContentWrapper>
                <ImagePreviewWrapper
                    onClick={handleUploadTempImageFileBtnClick}
                >
                    {isTempImageDataUrlPopulated ? (
                        <ImagePreview src={tempImageDataUrl} />
                    ) : (
                        <PlaceholderImagePreview>
                            <MaterialIcon>upload</MaterialIcon> Upload a 3D
                            render image...
                        </PlaceholderImagePreview>
                    )}
                </ImagePreviewWrapper>
                <VerticalFlexWrapper>
                    <FileInput
                        type="file"
                        accept="image/*"
                        onChange={onTempImageFileChange}
                        ref={uploadTempImageInputElement}
                    />
                    <VerticalFlexWrapper>
                        <HorizontalFlexWrapper>
                            OC Template: &nbsp;
                            <select onChange={onOcIdChange} value={ocId}>
                                {Object.keys(OC_ID_MAP).map((ocId: string) => (
                                    <option key={ocId} value={ocId}>
                                        {
                                            OC_ID_MAP[
                                                ocId as keyof typeof OC_ID_MAP
                                            ]
                                        }
                                    </option>
                                ))}
                            </select>
                        </HorizontalFlexWrapper>
                        <HiddenUploadBtn
                            onClick={handleUploadTempImageFileBtnClick}
                        />
                        <PositiveBtn
                            disabled={!isTempImageDataUrlPopulated}
                            onClick={submitTempImageFile}
                        >
                            <MaterialIcon>send</MaterialIcon> Generate 2D Image
                        </PositiveBtn>
                    </VerticalFlexWrapper>
                </VerticalFlexWrapper>
            </ContentWrapper>
            <Guide
                title={OC_ID_MAP[ocId as keyof typeof OC_ID_MAP]}
                sampleInputImage={
                    OC_SAMPLE_INPUT_MAP[
                        ocId as keyof typeof OC_SAMPLE_INPUT_MAP
                    ]
                }
                sampleOutputImage={
                    OC_SAMPLE_OUTPUT_FULL_MAP[
                        ocId as keyof typeof OC_SAMPLE_OUTPUT_FULL_MAP
                    ]
                }
            />
        </VerticalFlexWrapper>
    );

    return captured3dFrame ? <Result /> : mainContent;
};

export default UploadRender;
