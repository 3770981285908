import React, { useReducer } from "react";

import { AppContext, initialState, reducer } from "../../reducer";
import Navbar from "../NavigationBar";
import GlobalStyle from "../styled/global";
import {
    DisclaimerLabel,
    MainAppWrapper,
    TabWrapper,
    WindowWrapper,
    WorkstationWrapper,
} from "./styled";

import { fetchToken } from "../../services/Auth";
import Auth from "../Auth";

import {
    Route,
    HashRouter as Router,
    Routes,
    useLocation,
} from "react-router-dom";
import MainCamera from "../MainCamera";
import UploadRender from "../UploadRender";
import { LinkBtn } from "../styled/buttons";

const PATH_ROOT = "/";
const PATH_UPLOAD = "/upload-render";
const PATH_UPLOAD_URL = `/#${PATH_UPLOAD}`;

const App: React.FC = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };
    const token = fetchToken();
    const { captured3dFrame } = state;

    return (
        <Router>
            <AppContext.Provider value={value}>
                <WindowWrapper>
                    <GlobalStyle />
                    <Navbar />
                    <DisclaimerLabel>
                        NOTE: This is currently a demo alpha version with slower
                        processing time and possible bugs
                    </DisclaimerLabel>
                    <MainAppWrapper>
                        <WorkstationWrapper>
                            {token ? (
                                <MainContent
                                    captured3dFrame={captured3dFrame}
                                />
                            ) : (
                                <Auth />
                            )}
                        </WorkstationWrapper>
                    </MainAppWrapper>
                </WindowWrapper>
            </AppContext.Provider>
        </Router>
    );
};

type MainContentProps = { captured3dFrame: string };

const MainContent: React.FC<MainContentProps> = (props: MainContentProps) => {
    const { captured3dFrame } = props;
    const { pathname } = useLocation();

    return (
        <div>
            {!captured3dFrame && (
                <TabWrapper>
                    <LinkBtn href={PATH_ROOT} selected={pathname === PATH_ROOT}>
                        MoCap
                    </LinkBtn>
                    <LinkBtn
                        href={PATH_UPLOAD_URL}
                        selected={pathname === PATH_UPLOAD}
                    >
                        Upload
                    </LinkBtn>
                </TabWrapper>
            )}
            <Routes>
                <Route path={PATH_UPLOAD} element={<UploadRender />} />
                <Route path={PATH_ROOT} element={<MainCamera />} />
            </Routes>
        </div>
    );
};

export default App;
