import { createContext } from "react";

import {
    SET_CAPTURED_3D_FRAME,
    RESET,
    SET_CAMERA_SCREENSHOT_FRAME,
    SET_MAIN_3D_FRAME,
    SET_OC_ID,
    SET_API_URL,
} from "./actions";
import {
    Action,
    SetCaptured3dFramePayload,
    State,
    AppContextType,
    SetCameraScreenshotPayload,
    SetMain3dFramePayload,
    SetOcIdPayload,
    SetApiUrlPayload,
} from "./types";
import { DEFAULT_OC_ID } from "../components/MainCamera/MotionCaptureFeed/constants";
import { LUNA_API_URL } from "../components/Result/constants";

const AppContext = createContext<AppContextType | null>(null);

const initialState: State = {
    cameraScreenshot: "",
    main3dFrame: "",
    captured3dFrame: "",
    mainMannequinLoadingProgress: 0,
    segmentedMannequinLoadingProgress: 0,
    ocId: DEFAULT_OC_ID,
    apiUrl: LUNA_API_URL,
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
    switch (type) {
        case SET_CAMERA_SCREENSHOT_FRAME:
            return {
                ...state,
                cameraScreenshot: (payload as SetCameraScreenshotPayload)
                    .cameraScreenshot,
            };
        case SET_MAIN_3D_FRAME:
            return {
                ...state,
                main3dFrame: (payload as SetMain3dFramePayload).main3dFrame,
            };
        case SET_CAPTURED_3D_FRAME:
            return {
                ...state,
                captured3dFrame: (payload as SetCaptured3dFramePayload)
                    .captured3dFrame,
            };
        case SET_OC_ID:
            return {
                ...state,
                ocId: (payload as SetOcIdPayload).ocId,
            };
        case SET_API_URL:
            return {
                ...state,
                apiUrl: (payload as SetApiUrlPayload).apiUrl,
            };
        case RESET:
            return {
                ...state,
                cameraScreenshot: "",
                main3dFrame: "",
                captured3dFrame: "",
                apiUrl: LUNA_API_URL,
            };
        default:
            return state;
    }
};

export { reducer, initialState, AppContext };
