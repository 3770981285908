import styled from "styled-components";
import { HorizontalFlexWrapper, VerticalFlexWrapper } from "../styled/wrappers";
import { textColorHint } from "../styled/colors";

const WorkstationWrapper = styled(HorizontalFlexWrapper)`
    margin: 0.5em 0.25em;
    padding: 1em;
    border-radius: 2px;
    background: #fff;
`;

const SavedCharactersWrapper = styled(VerticalFlexWrapper)`
    margin: 2em 0;
    width: 50px;
    min-height: 2px;
    justify-content: flex-start;
    border-radius: 2px;
    background: #fff;
`;

const MainAppWrapper = styled(HorizontalFlexWrapper)`
    justify-content: center;
    align-items: flex-start;
`;

const WindowWrapper = styled(VerticalFlexWrapper)`
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const DisclaimerLabel = styled.span`
    margin-top: 1em;
    color: ${textColorHint};
    font-size: 0.8em;
`;

const TabWrapper = styled(HorizontalFlexWrapper)`
    margin-bottom: 0.5em;
    justify-content: flex-start;
`;

export {
    MainAppWrapper,
    WorkstationWrapper,
    SavedCharactersWrapper,
    WindowWrapper,
    DisclaimerLabel,
    TabWrapper,
};
