import { Holistic } from "@mediapipe/holistic";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { VRM, VRMUtils } from "@pixiv/three-vrm";

import { mannequinScene } from "./environment";
import { animate, onResults } from "./callbacks";
import { SEGMENTED_VRM_URL, URL_SEPARATOR } from "./constants";
import { MEDIAPIPE_BIN_URL } from "./config";

const holisticMocapInstance = new Holistic({
    locateFile: (file) => {
        return `${MEDIAPIPE_BIN_URL}${URL_SEPARATOR}${file}`;
    },
});
const mannequinModelLoader = new GLTFLoader();
let mannequinVrmInstance = null;

const initAnimation = (videoElement, guideCanvasElement) => {
    holisticMocapInstance.onResults(
        onResults(videoElement, guideCanvasElement)
    );
    animate();
};

const initHolistic = () => {
    holisticMocapInstance.setOptions({
        modelComplexity: 1,
        smoothLandmarks: true,
        minDetectionConfidence: 0.7,
        minTrackingConfidence: 0.7,
        refineFaceLandmarks: true,
    });
};

const initMannequinModelLoader = () => {
    mannequinModelLoader.crossOrigin = "anonymous";
    mannequinModelLoader.load(
        SEGMENTED_VRM_URL,

        (gltf) => {
            VRMUtils.removeUnnecessaryJoints(gltf.scene);

            VRM.from(gltf).then((vrm) => {
                mannequinScene.add(vrm.scene);
                mannequinVrmInstance = vrm;
                mannequinVrmInstance.scene.rotation.y = Math.PI; // Rotate model 180deg to face camera
            });
        },

        (progress) =>
            console.log(
                "Loading mannequin model...",
                100.0 * (progress.loaded / progress.total),
                "%"
            ),

        (error) => console.error(error)
    );
};

export {
    initAnimation,
    initHolistic,
    initMannequinModelLoader,
    holisticMocapInstance,
    mannequinModelLoader,
    mannequinVrmInstance,
};
