import styled from "styled-components";
import { INPUT_DIMENSION } from "../../services/MotionCapture/camera/config";
import { HorizontalFlexWrapper } from "../styled/wrappers";
import { textColorHint } from "../styled/colors";

const ImagePreview = styled.img`
    width: ${INPUT_DIMENSION}px;
    height: ${INPUT_DIMENSION}px;
    background: repeating-conic-gradient(#dddddd 0% 25%, transparent 0% 50%) 50% /
        20px 20px;
    border: 1px solid gray;
`;

const FlippedImagePreview = styled(ImagePreview)`
    transform: scaleX(-1);
`;



const Output2dImagePreviewWrapper = styled(HorizontalFlexWrapper)`
    width: ${INPUT_DIMENSION}px;
    height: ${INPUT_DIMENSION}px;
    margin: 0.5em 0.75em;
`;

export {
    ImagePreview,
    FlippedImagePreview,
    Output2dImagePreviewWrapper,
};
