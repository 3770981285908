import { Camera } from "@mediapipe/camera_utils";

import { holisticMocapInstance } from "../mannequin";
import { INPUT_DIMENSION } from "./config";

// Camera
const initCamera = (videoElement) => {
    const camera = new Camera(videoElement, {
        onFrame: async () => {
            await holisticMocapInstance.send({ image: videoElement });
        },
        width: INPUT_DIMENSION,
        height: INPUT_DIMENSION,
    });

    camera.start();
};

export { initCamera };
