import styled from "styled-components";
import { textColorHint } from "./colors";

const Title = styled.span`
    font-size: 1em;
    font-weight: 900;
`;

const Subtext = styled.span`
    color: #eeeeee;
    font-size: 0.5em;
`;

const StepTitle = styled(Title)`
    color: ${textColorHint};
    font-size: 1.25em;
    margin-bottom: 0.5em;
`;

export { Title, Subtext, StepTitle };
