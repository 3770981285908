import styled from "styled-components";
import { themeColorLight } from "./colors";

const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

const HorizontalFlexWrapper = styled(FlexWrapper)`
    flex-direction: row;
`;

const VerticalFlexWrapper = styled(FlexWrapper)`
    flex-direction: column;
`;

const ContentWrapper = styled(VerticalFlexWrapper)`
    border-top: 1px solid ${themeColorLight};
    border-bottom: 1px solid ${themeColorLight};
    padding: 0.5em 0;
`;

const ButtonBarWrapper = styled(HorizontalFlexWrapper)`
    padding: 0.25em 0;
`;

export {
    ContentWrapper,
    HorizontalFlexWrapper,
    VerticalFlexWrapper,
    ButtonBarWrapper,
};
