import React, { useContext } from "react";

import { AppContext } from "../../reducer";
import Result from "../Result";
import MotionCaptureFeed from "./MotionCaptureFeed";
import { AppContextType } from "../../reducer/types";

const MainCamera: React.FC = () => {
    const { state } = useContext(AppContext) as AppContextType;
    const { captured3dFrame } = state;

    return captured3dFrame ? <Result /> : <MotionCaptureFeed />;
};

export default MainCamera;
