import styled, { keyframes } from "styled-components";
import { themeColorLight } from "./colors";

const loadingAnimation = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
`;

const LoadingIndicator = styled.span`
    animation: ${loadingAnimation} 1s linear infinite;
    color: ${themeColorLight};
`;

const MaterialIcon = styled.i.attrs(() => ({ className: "material-icons" }))`
    font-size: 1em;
    margin-right: 0.5em;
`;

export { LoadingIndicator, MaterialIcon };
