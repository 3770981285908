export const SHOULD_RENDER_ALPHA = true;
export const SHOULD_PRESERVE_DRAWING_BUFFER = true;

export const CAM_FIELD_OF_VIEW = 18;
export const CAM_ASPECT_RATIO = 1;
export const CAM_PLANE_NEAR = 0.1;
export const CAM_PLANE_FAR = 1000;
export const CAM_POS_X = 0.0;
export const CAM_POS_Y = 1;
export const CAM_POS_Y_MANNEQUIN = 0.6;
export const CAM_POS_Z = 3.5;
export const CAM_POS_Z_MANNEQUIN = 1.5;

export const CONTROLS_ALLOW_PAN_SCREEN = false;
export const CONTROLS_TARGET_X = 0.0;
export const CONTROLS_TARGET_Y = 1.6;
export const CONTROLS_TARGET_Y_MANNEQUIN = 1.3;
export const CONTROLS_TARGET_Z = 0.0;

export const BG_COLOUR = 0xffffff;

export const LIGHT_COLOUR = 0xffffff;
export const LIGHT_POS_X = 0.0;
export const LIGHT_POS_Y = 2;
export const LIGHT_POS_Z = 1.7392;
export const LIGHT_INTENSITY = 0.7;
export const LIGHT_INTENSITY_MANNEQUIN = 0.55;
