import { useLocation, Navigate } from "react-router-dom";

const LOCAL_STORAGE_TOKEN_KEY = "betaKeyJwtToken";
const AUTH_API_URL = "api/auth";
const HASHED_BETA_KEY_SELECTOR = "hashed_beta_key";

const setToken = (token) => {
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token); // make up your own token
};

const fetchToken = () => {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
};

const RequireToken = ({ children }) => {
    const auth = fetchToken();
    const location = useLocation();

    if (!auth) {
        return <Navigate to="/" state={{ from: location }} />;
    }

    return children;
};

export {
    setToken,
    fetchToken,
    RequireToken,
    AUTH_API_URL,
    HASHED_BETA_KEY_SELECTOR,
};
