import {
    AUTH_API_URL,
    HASHED_BETA_KEY_SELECTOR,
    setToken,
} from "../../services/Auth";
import { useState } from "react";
import axios from "axios";
import { ErrorMessage } from "./styled";
import { VerticalFlexWrapper } from "../styled/wrappers";
import { PositiveBtn } from "../styled/buttons";

const Auth: React.FC = () => {
    const [hashedBetaKey, setHashedBetaKey] = useState("");
    const [error, setError] = useState("");

    const authenticate = () => {
        setError("");

        if (hashedBetaKey == "") {
            return;
        } else {
            axios
                .post(AUTH_API_URL, {
                    [HASHED_BETA_KEY_SELECTOR]: hashedBetaKey,
                })
                .then((response) => {
                    if (response.data.token) {
                        setToken(response.data.token);
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    const errorMessage = `${error.message}: ${error.response.data.detail}`;
                    setError(errorMessage);
                });
        }
    };

    return (
        <form>
            <VerticalFlexWrapper>
                <label>Input Beta Key</label>
                <input
                    type="text"
                    onChange={(e) => setHashedBetaKey(e.target.value)}
                />
                <br />
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <PositiveBtn type="button" onClick={authenticate}>
                    Submit
                </PositiveBtn>
            </VerticalFlexWrapper>
        </form>
    );
};

export default Auth;
