import React from "react";
import logo from "../../img/logo.png";
import { Link } from "../styled/buttons";
import { MaterialIcon } from "../styled/icons";
import { Subtext, Title } from "../styled/labels";

import { Logo, NavbarWrapper, TitleTextWrapper, TitleWrapper } from "./styled";

const Navbar: React.FC = () => (
    <NavbarWrapper>
        <TitleWrapper>
            <Logo src={logo} />
            <TitleTextWrapper>
                <Title>KaiA</Title>
                <Subtext>Storytelling on the Go!</Subtext>
            </TitleTextWrapper>
        </TitleWrapper>
        <Link href="https://555comic.com" target="_blank">
            <MaterialIcon>info</MaterialIcon>
        </Link>
    </NavbarWrapper>
);

export default Navbar;
