import styled from "styled-components";
import {
    negativeColor,
    negativeColorHover,
    neutralColor,
    neutralColorHover,
    neutralTextColor,
    subThemeColor,
    subThemeColorDisabled,
    subThemeColorHover,
    textColor,
    textColorHint,
    themeColor,
} from "./colors";

const Button = styled.button`
    border: 0;
    border-radius: 0.25em;
    margin: 5px;
    padding: 0.5em 1em;
    font-size: 0.8em;
    cursor: pointer;
    color: #fff;
    transition: 0.2s ease-in;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const PositiveBtn = styled(Button)`
    background: ${subThemeColor};
    &:hover {
        background: ${subThemeColorHover};
    }
    &:disabled {
        background: ${subThemeColorDisabled};
        cursor: not-allowed;
    }
`;

const NeutralBtn = styled(Button)`
    color: ${neutralTextColor};
    background: ${neutralColor};
    &:hover {
        background: ${neutralColorHover};
    }
    &:disabled {
        background: ${subThemeColorDisabled};
    }
`;

const NegativeBtn = styled(Button)`
    background: ${negativeColor};
    &:hover {
        background: ${negativeColorHover};
    }
`;

const Link = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${textColor};
    cursor: pointer;
`;

const LinkBtn = styled(Link)<{ selected?: boolean }>`
    color: ${(props) => (props.selected ? textColor : themeColor)};
    background: ${(props) => (props.selected ? themeColor : "none")};
    border: 1px solid ${(props) => (props.selected ? themeColor : themeColor)};
    padding: 0.25em 0.5em;
    font-size: 0.75em;
`;

export { PositiveBtn, NeutralBtn, NegativeBtn, Link, LinkBtn };
