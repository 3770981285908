import longHairFull from "../../../img/samples/oc/01_blossom_full.png";
import longHairSketch from "../../../img/samples/oc/01_blossom_sketch.png";
import longHairInput from "../../../img/samples/oc/01_blossom.png";

import pigtailFull from "../../../img/samples/oc/02_bubbles_full.png";
import pigtailSketch from "../../../img/samples/oc/02_bubbles_sketch.png";
import pigtailInput from "../../../img/samples/oc/02_bubbles.png";

import bobcutFull from "../../../img/samples/oc/03_buttercup_full.png";
import bobcutSketch from "../../../img/samples/oc/03_buttercup_sketch.png";
import bobcutInput from "../../../img/samples/oc/03_buttercup.png";

const MIME_TYPE_STR = "image/png";

// TODO: put back to 3000
const FRAME_CAP_TIMEOUT_MS = 3000;
const FRAME_CAP_TIMEOUT_SEC = FRAME_CAP_TIMEOUT_MS / 1000;

const OC_ID_BLOSSOM = "shiiWoh4po";
const OC_ID_BUBBLES = "jicoayu7Xi";
const OC_ID_BUTTERCUP = "oVeiw8eNga";
const DEFAULT_OC_ID = OC_ID_BLOSSOM;

const OC_ID_MAP = {
    [OC_ID_BLOSSOM]: "Long Hair",
    [OC_ID_BUBBLES]: "Pigtail",
    [OC_ID_BUTTERCUP]: "Bobcut",
};

const OC_SAMPLE_INPUT_MAP = {
    [OC_ID_BLOSSOM]: longHairInput,
    [OC_ID_BUBBLES]: pigtailInput,
    [OC_ID_BUTTERCUP]: bobcutInput,
};

const OC_SAMPLE_OUTPUT_SKETCH_MAP = {
    [OC_ID_BLOSSOM]: longHairSketch,
    [OC_ID_BUBBLES]: pigtailSketch,
    [OC_ID_BUTTERCUP]: bobcutSketch,
};

const OC_SAMPLE_OUTPUT_FULL_MAP = {
    [OC_ID_BLOSSOM]: longHairFull,
    [OC_ID_BUBBLES]: pigtailFull,
    [OC_ID_BUTTERCUP]: bobcutFull,
};

export {
    MIME_TYPE_STR,
    FRAME_CAP_TIMEOUT_MS,
    FRAME_CAP_TIMEOUT_SEC,
    OC_ID_MAP,
    DEFAULT_OC_ID,
    OC_SAMPLE_INPUT_MAP,
    OC_SAMPLE_OUTPUT_SKETCH_MAP,
    OC_SAMPLE_OUTPUT_FULL_MAP,
};
