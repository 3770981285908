import styled from "styled-components";

import {
    subThemeColor,
    textColorHint,
    themeColor,
    themeColorLight,
} from "../styled/colors";
import { HorizontalFlexWrapper, VerticalFlexWrapper } from "./wrappers";

const GuideTitleWrapper = styled(HorizontalFlexWrapper)`
    font-size: 0.75em;
    color: ${textColorHint};
    margin: 0.5em 0 0.75em 0;
`;

const GuideTitle = styled.span`
    font-weight: 900;
    color: ${themeColor};
`;

const GuideTable = styled.table`
    border: none;
`;

const GuideTableHeader = styled.th`
    border-bottom: 1px solid ${themeColorLight};
    margin: 0 0.25em;
    font-size: 0.75em;
    color: ${textColorHint};
`;

const GuideTableCell = styled.td``;

const GuideImage = styled.img`
    width: 150px;
    height: 150px;
    background: repeating-conic-gradient(#dddddd 0% 25%, transparent 0% 50%) 50% /
        20px 20px;
`;

export {
    GuideTitleWrapper,
    GuideTitle,
    GuideTable,
    GuideTableHeader,
    GuideTableCell,
    GuideImage,
};
