import styled from "styled-components";
import { INPUT_DIMENSION } from "../../../services/MotionCapture/camera/config";

const VideoGuideWrapper = styled.div`
    position: relative;
    width: ${INPUT_DIMENSION}px;
    height: ${INPUT_DIMENSION}px;
    margin: 0.5em 0.75em;
`;

const Video = styled.video`
    width: ${INPUT_DIMENSION}px;
    height: ${INPUT_DIMENSION}px;
    transform: scale(-1, 1);
    position: absolute;
`;

const GuideCanvas = styled.canvas`
    width: ${INPUT_DIMENSION}px;
    height: ${INPUT_DIMENSION}px;
    transform: scale(-1, 1);
`;

const TouchPrevention = styled.div`
    position: absolute;
    background: transparent;
    width: ${INPUT_DIMENSION}px;
    height: ${INPUT_DIMENSION}px;
`;

const MannequinPreview = styled.div`
    width: ${INPUT_DIMENSION}px;
    height: ${INPUT_DIMENSION}px;
    background: repeating-conic-gradient(#dddddd 0% 25%, transparent 0% 50%) 50% /
        20px 20px;
`;

const MainModelCanvas = styled(MannequinPreview)`
    display: none;
`;

const FileInput = styled.input`
    display: none;
`

export {
    VideoGuideWrapper,
    Video,
    GuideCanvas,
    MannequinPreview,
    MainModelCanvas,
    TouchPrevention,
    FileInput,
};

