import styled from "styled-components";
import { INPUT_DIMENSION } from "../../services/MotionCapture/camera/config";
import { textColorHint } from "../styled/colors";
import { HorizontalFlexWrapper } from "../styled/wrappers";

const PlaceholderImagePreview = styled(HorizontalFlexWrapper)`
    width: ${INPUT_DIMENSION}px;
    height: ${INPUT_DIMENSION}px;
    border: 1px solid ${textColorHint};
    color: ${textColorHint};
`;

const ImagePreviewWrapper = styled.div`
    cursor: pointer;
`;

const HiddenUploadBtn = styled.button`
    display: none;
`;

export { HiddenUploadBtn, ImagePreviewWrapper, PlaceholderImagePreview };
