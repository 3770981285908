import React from "react";
import { GuideProps } from "./types";
import {
    GuideImage,
    GuideTable,
    GuideTableCell,
    GuideTableHeader,
    GuideTitle,
    GuideTitleWrapper,
} from "../styled/guide";
import { MaterialIcon } from "../styled/icons";
import { VerticalFlexWrapper } from "../styled/wrappers";

const Guide: React.FC<GuideProps> = (props: GuideProps) => {
    const { title, sampleInputImage, sampleOutputImage }: GuideProps = props;

    return (
        <VerticalFlexWrapper>
            <GuideTitleWrapper>
                <MaterialIcon>info</MaterialIcon>Samples for&nbsp;
                <GuideTitle>{title}</GuideTitle>
            </GuideTitleWrapper>
            <GuideTable>
                <thead>
                    <tr>
                        <GuideTableHeader>Sample Input</GuideTableHeader>
                        <GuideTableHeader>Possible Output</GuideTableHeader>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <GuideTableCell>
                            <GuideImage src={sampleInputImage} />
                        </GuideTableCell>
                        <GuideTableCell>
                            <GuideImage src={sampleOutputImage} />
                        </GuideTableCell>
                    </tr>
                </tbody>
            </GuideTable>
        </VerticalFlexWrapper>
    );
};

export default Guide;
