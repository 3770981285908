import React, { useContext, useEffect, useRef, useState } from "react";

import {
    initMocap,
    isMocapInitialised,
    setIsMocapInitialised,
} from "../../../services/MotionCapture";
import { mannequinRenderer } from "../../../services/MotionCapture/mannequin/environment";

import { PositiveBtn } from "../../styled/buttons";
import {
    ButtonBarWrapper,
    ContentWrapper,
    HorizontalFlexWrapper,
    VerticalFlexWrapper,
} from "../../styled/wrappers";
import {
    GuideCanvas,
    MainModelCanvas,
    MannequinPreview,
    Video,
    VideoGuideWrapper,
} from "./styled";

import { FRAME_CAP_TIMEOUT_SEC, MIME_TYPE_STR } from "./constants";

import { AppContext } from "../../../reducer";
import {
    SET_API_URL,
    SET_CAMERA_SCREENSHOT_FRAME,
    SET_CAPTURED_3D_FRAME,
    SET_MAIN_3D_FRAME,
} from "../../../reducer/actions";
import { AppContextType } from "../../../reducer/types";
import { mainModelRenderer } from "../../../services/MotionCapture/mainModel/environment";
import { LoadingIndicator, MaterialIcon } from "../../styled/icons";

import { modelLoadProgress } from "../../../services/MotionCapture/mainModel";
import { isHolisticLoaded } from "../../../services/MotionCapture/mannequin/callbacks";
import { LUNA_API_URL } from "../../Result/constants";

import lunaPoseSample from "../../../img/samples/mc/01_luna_pose.png";
import lunaFullSample from "../../../img/samples/mc/01_luna_full.png";
import lunaSketchSample from "../../../img/samples/mc/01_luna_sketch.png";
import Guide from "../../Guide";

const MotionCaptureFeed: React.FC = () => {
    const { dispatch } = useContext(AppContext) as AppContextType;

    const [isCountdownRunning, setIsCountdownRunning] = useState(false);
    const [counter, setCounter] = useState(FRAME_CAP_TIMEOUT_SEC);
    const [modelLoadProgressUi, setModelLoadProgressUi] = useState(0);
    const [isHolisticLoadedUi, setIsHolisticLoadedUi] = useState(false);
    const [fakeHolisticLoadingProgressTimestamp, _] = useState(Date.now());
    const [fakeHolisticLoadingProgress, setFakeHolisticLoadingProgress] =
        useState(0);

    const mannequinPreviewElement =
        useRef() as React.MutableRefObject<HTMLDivElement>;
    const mainModelCanvasElement =
        useRef() as React.MutableRefObject<HTMLDivElement>;
    const videoElement = useRef() as React.MutableRefObject<HTMLVideoElement>;
    const guideCanvasElement =
        useRef() as React.MutableRefObject<HTMLCanvasElement>;

    const startCountdown = () => {
        setCounter(FRAME_CAP_TIMEOUT_SEC);
        setIsCountdownRunning(true);
    };

    const getFakeHolisticLoadingProgress = () => {
        const now = Date.now();
        const timeDiff = now - fakeHolisticLoadingProgressTimestamp;
        const progress = Math.min(100, (timeDiff / 5000) * 100);

        return progress;
    };

    useEffect(() => {
        if (!isMocapInitialised) {
            initMocap(videoElement.current, guideCanvasElement.current);
            mainModelCanvasElement.current.append(mainModelRenderer.domElement);
            mannequinPreviewElement.current.append(
                mannequinRenderer.domElement
            );
        }

        const stopModelProgressCheckInterval = setInterval(() => {
            setModelLoadProgressUi(Number(modelLoadProgress.toFixed(2)));

            if (modelLoadProgress === 100) {
                clearInterval(stopModelProgressCheckInterval);
            }
        }, 10);

        const stopMocapInitCheckInterval = setInterval(() => {
            setIsHolisticLoadedUi(isHolisticLoaded);
            setFakeHolisticLoadingProgress(
                Number(getFakeHolisticLoadingProgress().toFixed(2))
            );

            if (isHolisticLoaded) {
                clearInterval(stopMocapInitCheckInterval);
            }
        }, 10);
    }, []);

    useEffect(() => {
        const saveFrameToContext = () => {
            const cameraScreenshot =
                guideCanvasElement.current.toDataURL(MIME_TYPE_STR);
            const main3dFrame =
                mainModelRenderer.domElement.toDataURL(MIME_TYPE_STR);
            const captured3dFrame =
                mannequinRenderer.domElement.toDataURL(MIME_TYPE_STR);

            dispatch({
                type: SET_API_URL,
                payload: {
                    apiUrl: LUNA_API_URL,
                },
            });

            dispatch({
                type: SET_CAMERA_SCREENSHOT_FRAME,
                payload: {
                    cameraScreenshot,
                },
            });

            dispatch({
                type: SET_MAIN_3D_FRAME,
                payload: {
                    main3dFrame,
                },
            });

            dispatch({
                type: SET_CAPTURED_3D_FRAME,
                payload: {
                    captured3dFrame,
                },
            });
            setIsMocapInitialised(false);
        };

        counter > 0 &&
            isCountdownRunning &&
            setTimeout(() => {
                if (counter > 1) {
                    setCounter(counter - 1);
                } else {
                    saveFrameToContext();
                    setIsCountdownRunning(false);
                    setCounter(FRAME_CAP_TIMEOUT_SEC);
                }
            }, 1000);
    }, [counter, isCountdownRunning, dispatch]);

    const isModelInitialized = modelLoadProgressUi === 100;

    return (
        <VerticalFlexWrapper>
            <ContentWrapper>
                {!isModelInitialized && (
                    <LoadingIndicator>
                        (1/2) Loading 3D models {modelLoadProgressUi}%...
                    </LoadingIndicator>
                )}
                {isModelInitialized && !isHolisticLoadedUi && (
                    <LoadingIndicator>
                        (2/2) Loading mocap components{" "}
                        {fakeHolisticLoadingProgress}
                        %...
                    </LoadingIndicator>
                )}
                <HorizontalFlexWrapper
                    style={
                        isModelInitialized && isHolisticLoadedUi
                            ? {}
                            : { display: "none" }
                    }
                >
                    <VideoGuideWrapper>
                        <Video ref={videoElement} autoPlay muted playsInline />
                        <GuideCanvas ref={guideCanvasElement} />
                    </VideoGuideWrapper>
                    <VideoGuideWrapper>
                        {/* <TouchPrevention /> */}
                        <MannequinPreview ref={mannequinPreviewElement} />
                    </VideoGuideWrapper>
                    <MainModelCanvas ref={mainModelCanvasElement} />
                </HorizontalFlexWrapper>

                <ButtonBarWrapper
                    style={
                        isModelInitialized && isHolisticLoadedUi
                            ? {}
                            : { display: "none" }
                    }
                >
                    <PositiveBtn
                        onClick={startCountdown}
                        disabled={isCountdownRunning}
                    >
                        {isCountdownRunning &&
                            `Capturing in ${counter} seconds...`}
                        {!isCountdownRunning && (
                            <>
                                <MaterialIcon>photo_camera</MaterialIcon> Start
                                Pose Countdown ({FRAME_CAP_TIMEOUT_SEC} secs.)
                            </>
                        )}
                    </PositiveBtn>
                </ButtonBarWrapper>
            </ContentWrapper>
            <Guide
                title="Luna"
                sampleInputImage={lunaPoseSample}
                sampleOutputImage={lunaFullSample}
            />
        </VerticalFlexWrapper>
    );
};

export default MotionCaptureFeed;
