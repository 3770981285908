import { Pose, Hand, Face } from "kalidokit";

import {
    clock,
    orbitCamera,
    mainModelRenderer,
    mainModelScene,
} from "./environment";
import { rigFace, rigRotation, rigPosition } from "../rig";
import { mainVrmInstance } from ".";
import { DEFAULT_DAMPENER, DEFAULT_LERP_AMOUNT } from "../rig/config";

// VRM Model Update
// Original code by @yeemachine
// https://github.com/yeemachine
const animateMainModelVRM = (vrm, results, videoElement) => {
    if (!vrm) {
        return;
    }
    // Take the results from `Holistic` and animate character based on its Face, Pose, and Hand Keypoints.
    let riggedPose, riggedLeftHand, riggedRightHand, riggedFace;

    const faceLandmarks = results.faceLandmarks;
    // Pose 3D Landmarks are with respect to Hip distance in meters
    const pose3DLandmarks = results.ea;
    // Pose 2D landmarks are with respect to videoWidth and videoHeight
    const pose2DLandmarks = results.poseLandmarks;
    // Be careful, hand landmarks may be reversed
    const leftHandLandmarks = results.rightHandLandmarks;
    const rightHandLandmarks = results.leftHandLandmarks;

    // Animate Face
    if (faceLandmarks) {
        riggedFace = Face.solve(faceLandmarks, {
            runtime: "mediapipe",
            video: videoElement,
        });
        rigFace(riggedFace, vrm);
    }

    // Animate Pose
    if (pose2DLandmarks && pose3DLandmarks) {
        riggedPose = Pose.solve(pose3DLandmarks, pose2DLandmarks, {
            runtime: "mediapipe",
            video: videoElement,
        });
        rigRotation(
            "Hips",
            riggedPose.Hips.rotation,
            0.7,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigPosition(
            "Hips",
            {
                x: 0, // Keep in centre
                y: riggedPose.Hips.position.y + 1, // Add a bit of height
                z: -riggedPose.Hips.position.z, // Reverse direction
            },
            1,
            0.07,
            vrm
        );

        rigRotation("Chest", riggedPose.Spine, 0.25, 0.3, vrm);
        rigRotation("Spine", riggedPose.Spine, 0.45, 0.3, vrm);

        rigRotation("RightUpperArm", riggedPose.RightUpperArm, 1, 0.3, vrm);
        rigRotation("RightLowerArm", riggedPose.RightLowerArm, 1, 0.3, vrm);
        rigRotation("LeftUpperArm", riggedPose.LeftUpperArm, 1, 0.3, vrm);
        rigRotation("LeftLowerArm", riggedPose.LeftLowerArm, 1, 0.3, vrm);

        rigRotation("LeftUpperLeg", riggedPose.LeftUpperLeg, 1, 0.3, vrm);
        rigRotation("LeftLowerLeg", riggedPose.LeftLowerLeg, 1, 0.3, vrm);
        rigRotation("RightUpperLeg", riggedPose.RightUpperLeg, 1, 0.3, vrm);
        rigRotation("RightLowerLeg", riggedPose.RightLowerLeg, 1, 0.3, vrm);
    }

    // Animate Hands
    if (leftHandLandmarks) {
        riggedLeftHand = Hand.solve(leftHandLandmarks, "Left");
        rigRotation(
            "LeftHand",
            {
                // Combine pose rotation Z and hand rotation X Y
                z: riggedPose.LeftHand.z,
                y: riggedLeftHand.LeftWrist.y,
                x: riggedLeftHand.LeftWrist.x,
            },
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftRingProximal",
            riggedLeftHand.LeftRingProximal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftRingIntermediate",
            riggedLeftHand.LeftRingIntermediate,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftRingDistal",
            riggedLeftHand.LeftRingDistal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftIndexProximal",
            riggedLeftHand.LeftIndexProximal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftIndexIntermediate",
            riggedLeftHand.LeftIndexIntermediate,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftIndexDistal",
            riggedLeftHand.LeftIndexDistal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftMiddleProximal",
            riggedLeftHand.LeftMiddleProximal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftMiddleIntermediate",
            riggedLeftHand.LeftMiddleIntermediate,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftMiddleDistal",
            riggedLeftHand.LeftMiddleDistal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftThumbProximal",
            riggedLeftHand.LeftThumbProximal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftThumbIntermediate",
            riggedLeftHand.LeftThumbIntermediate,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftThumbDistal",
            riggedLeftHand.LeftThumbDistal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftLittleProximal",
            riggedLeftHand.LeftLittleProximal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftLittleIntermediate",
            riggedLeftHand.LeftLittleIntermediate,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "LeftLittleDistal",
            riggedLeftHand.LeftLittleDistal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
    }
    if (rightHandLandmarks) {
        riggedRightHand = Hand.solve(rightHandLandmarks, "Right");
        rigRotation(
            "RightHand",
            {
                // Combine Z axis from pose hand and X/Y axis from hand wrist rotation
                z: riggedPose.RightHand.z,
                y: riggedRightHand.RightWrist.y,
                x: riggedRightHand.RightWrist.x,
            },
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightRingProximal",
            riggedRightHand.RightRingProximal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightRingIntermediate",
            riggedRightHand.RightRingIntermediate,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightRingDistal",
            riggedRightHand.RightRingDistal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightIndexProximal",
            riggedRightHand.RightIndexProximal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightIndexIntermediate",
            riggedRightHand.RightIndexIntermediate,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightIndexDistal",
            riggedRightHand.RightIndexDistal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightMiddleProximal",
            riggedRightHand.RightMiddleProximal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightMiddleIntermediate",
            riggedRightHand.RightMiddleIntermediate,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightMiddleDistal",
            riggedRightHand.RightMiddleDistal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightThumbProximal",
            riggedRightHand.RightThumbProximal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightThumbIntermediate",
            riggedRightHand.RightThumbIntermediate,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightThumbDistal",
            riggedRightHand.RightThumbDistal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightLittleProximal",
            riggedRightHand.RightLittleProximal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightLittleIntermediate",
            riggedRightHand.RightLittleIntermediate,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
        rigRotation(
            "RightLittleDistal",
            riggedRightHand.RightLittleDistal,
            DEFAULT_DAMPENER,
            DEFAULT_LERP_AMOUNT,
            vrm
        );
    }
};

const animate = () => {
    requestAnimationFrame(animate);

    if (mainVrmInstance) {
        // Update model to render physics
        mainVrmInstance.update(clock.getDelta());
    }
    mainModelRenderer.render(mainModelScene, orbitCamera);
};

export { animate, animateMainModelVRM };
