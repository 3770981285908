import styled from "styled-components";
import { themeColor, textColor, textColorHint } from "../styled/colors";
import { HorizontalFlexWrapper, VerticalFlexWrapper } from "../styled/wrappers";

const NavbarWrapper = styled(HorizontalFlexWrapper)`
    width: 100%;
    height: 55px;
    background-color: ${themeColor};
    justify-content: space-around;
`;

const TitleWrapper = styled(HorizontalFlexWrapper)`
    color: ${textColor};
`;

const TitleTextWrapper = styled(VerticalFlexWrapper)`
    align-items: flex-start;
`;

const Logo = styled.img`
    height: 30px;
    width: 30px;
    border-radius: 3px;
    margin-right: 0.5em;
`;



export { NavbarWrapper, TitleWrapper, TitleTextWrapper, Logo };
